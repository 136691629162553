<form novalidate [formGroup]="form" class="auth-form" (ngSubmit)="signup()">
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.name' | translate }}</mat-label>
      <input matInput formControlName="name" required />
      <mat-error [data-qa]="'invalid-name'" *ngIf="form.get('name')?.invalid">{{
        getErrorMessage('name')
      }}</mat-error>
      <mat-hint>{{ 'user.name_will_be_a_display_name' | translate }}</mat-hint>
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.email' | translate }}</mat-label>
      <input matInput formControlName="email" required />
      <mat-error [data-qa]="'invalid-email'" *ngIf="form.get('email')?.invalid">{{
        getErrorMessage('email')
      }}</mat-error>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.password' | translate }}</mat-label>
      <mzima-client-button
        matSuffix
        fill="clear"
        color="secondary"
        [iconOnly]="true"
        (buttonClick)="togglePasswordVisible()"
      >
        <mat-icon icon [svgIcon]="isPasswordVisible ? 'eye-open' : 'eye'"></mat-icon>
      </mzima-client-button>
      <input
        matInput
        formControlName="password"
        required
        [type]="isPasswordVisible ? 'text' : 'password'"
      />
      <mat-error [data-qa]="'invalid-password'" *ngIf="form.get('password')?.invalid">{{
        getErrorMessage('password')
      }}</mat-error>
      <mat-hint>{{ 'user.password_hint' | translate }}.</mat-hint>
    </mat-form-field>
    <app-password-strength [passwordToCheck]="form.value.password"></app-password-strength>
  </div>

  <div class="form-row">
    <img src="https://www.findthelost.co.za/lookup/captcha.php?id={{ getCaptcha() }}" height="100px"/>
    <mat-form-field appearance="fill">
      <mat-label>Captcha</mat-label>
      <input matInput formControlName="captcha" required />
    </mat-form-field>
  </div>

  <div class="form-row">
    <div class="checkbox">
      <mat-checkbox [data-qa]="'i-agree'" formControlName="agreement" required>
        <span [innerHTML]="'terms_of_service.i_agree' | translate"></span>
      </mat-checkbox>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <ng-content select="[btn-cancel]"></ng-content>

    <mzima-client-button
      type="submit"
      [disabled]="form.invalid || form.disabled || submitted"
      [data-qa]="'btn-register'"
    >
      {{ 'nav.register' | translate }}
    </mzima-client-button>
  </div>
</form>
